import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Styles } from '../styles';
import { navLinks } from '../constants';
import { aceLogo, menu, close } from '../assets';

const Navbar = () => {
  const [active, setActive] = useState('');
  var [toggle, setToggle] = useState(false);
  return (
    <nav class ={"${styles.paddingX} w-full flex items-center py-5 fixed top-0 z-20 bg-primary"}>
      <div class="w-full flex justify-between items-center max-w-7xl mx-auto">
        <Link to="/" class="flex items-center gap-2"
        onClick={() => {
          setActive("");
          // window.scrollTo(0,0);
        }}>
          <img src={aceLogo} alt="aceLogo" class="w-9 h-9 object-contain" />
          <p class="flex text-white text-[18px] font-bold cursor-pointer">Abdullah Bueno &nbsp;
          <span class="sm:block hidden">| Developer Portfolio</span></p>
        </Link>
        <ul class="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((Link) => (
            <li key={Link.id} class={`${
              active === Link.title
                ? "text-white"
                : "text-secondary"

            } hover:text-white text-lg font-medium cursor-pointer`}
            onClick={() => setActive(Link.title)}>
              <a href={`#${Link.id}`}> {Link.title}</a>
            </li>
          ))}
        </ul>

        <div class="sm:hidden flex flex-1 justify-end items-center">
          <img src={toggle ? close : menu} alt='menu' class="w-7 h-7 object-contain cursor-pointer" onClick={() => setToggle(!toggle)}/>

          <div class={`${!toggle ? 'hidden' : 'flex'}
          p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl`}>
            <ul class="list-none flex justify-end items-start flex-col gap-4">
          {navLinks.map((Link) => (
            <li key={Link.id} class={`${
              active === Link.title
                ? "text-white"
                : "text-secondary"

            } font-poppins font-medium cursor-pointer text-base`}
            onClick={() => { setToggle(!toggle); setActive(Link.title);}}>
              <a href={`#${Link.id}`}> {Link.title}</a>
            </li>
          ))}
        </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar