import {
    frontbackdev,
    android,
    dumbell,
    upfsm,
    javascript,
    typescript,
    html,
    css,
    reactjs,
    redux,
    tailwind,
    nodejs,
    mongodb,
    git,
    figma,
    docker,
    fullstack,
    csissues,
    CppLogo,
    webdev,
    LMS,
    NFA,
    mobileEmulator,
    threejs,
    galleryshooter,
    platformer,
    UPFapp,
    makelist,
  } from "../assets";
  
  export const navLinks = [
    {
      id: "about",
      title: "About",
    },
    {
      id: "work",
      title: "Work",
    },
    {
      id: "contact",
      title: "Contact",
    },
  ];
  
  const services = [
    {
      title: "Front & Back end Developer",
      icon: frontbackdev,
    },
    {
      title: "Mobile Developer",
      icon: android,
    },
    {
      title: "Personal Trainer",
      icon: dumbell,
    },
    {
      title: "Martial Arts Instructor",
      icon: upfsm,
    },
  ];
  
  const technologies = [
    {
      name: "HTML 5",
      icon: html,
    },
    {
      name: "CSS 3",
      icon: css,
    },
    {
      name: "JavaScript",
      icon: javascript,
    },
    {
      name: "TypeScript",
      icon: typescript,
    },
    {
      name: "React JS",
      icon: reactjs,
    },
    {
      name: "Redux Toolkit",
      icon: redux,
    },
    {
      name: "Tailwind CSS",
      icon: tailwind,
    },
    {
      name: "Node JS",
      icon: nodejs,
    },
    {
      name: "MongoDB",
      icon: mongodb,
    },
    {
      name: "Three JS",
      icon: threejs,
    },
    {
      name: "git",
      icon: git,
    },
    {
      name: "figma",
      icon: figma,
    },
    {
      name: "docker",
      icon: docker,
    },
  ];
  
  const experiences = [
    {
      title: "Professional Issues in Computer Science and Engineering",
      company_name: "University of South Carolina",
      icon: csissues,
      iconBg: "#383E56",
      date: "Spring 2019",
      points: [
        "Studied professional issues in the information technology professions.",
        "Learned history and social context of computing.",
        "Studied professional responsibilities, privacy, intellectual property, risks and liabilities of computer-based systems.",
      ],
    },
    {
      title: "Advanced Programming Techniques",
      company_name: "University of South Carolina",
      icon: CppLogo,
      iconBg: "#E6DEDD",
      date: "Fall 2021",
      points: [
        "Primarily a C++ based class with some focus on Unix programming.",
        "Studied concepts such as pointers, memory management, advanced programming language structures, operator overloading, iterators, multiple inheritance, polymorphism and virtual function.",
      ],
    },
    {
      title: "Cap Computing Project",
      company_name: "University of South Carolina",
      icon: webdev,
      iconBg: "#383E56",
      date: "Fall 2022 - Spring 2023",
      points: [
        "Collaborated with team of 5 to design and create Makelist: a web app that allows users to customize their grocery list based on preferences.",
        "Computer system implementation, testing, verification and validation of results.",
        "Written reports and oral presentations in a technical setting.",
      ],
    },
    {
      title: "Software Engineering",
      company_name: "University of South Carolina",
      icon: fullstack,
      iconBg: "#E6DEDD",
      date: "Spring 2023",
      points: [
        "Fundamentals of software design and development, software implementation strategies, object-oriented design techniques, functional design techniques, design patterns, design process, source control, testing.",
        "Conducted Comprehensive JUnit testing over a two-week period ensuring robustness and identifying and fixing bugs.",
        "Worked as a developer and worked with a team in the design, development and delivery of a Learning Management System using SCRUM methodology.",
      ],
    },
  ];
  
  const projects = [
    {
      name: "Shooting Gallery",
      description:
        "Through the help of udemy, created a Lua shooting gallery that utilizes fundamental programming techniques such as conditional statements, loops, functions and also implemented a custom high score leaderboard feature.",
      tags: [
        {
          name: "Udemy",
          color: "green-text-gradient"
        },
        {
          name: "Lua",
          color: "blue-text-gradient",
        },
      ],
      image: galleryshooter,
      source_code_link: "https://github.com/Bueno803/lua-projects",
    },
    {
      name: "Platformer",
      description:
        "Through the help of udemy, create a Lua Platformer where I implemented challenging obstacles and enemies with a goal at the end, while also uitilizing collision detection and a physics engine to offer responsive realistic interactions.",
      tags: [
        {
          name: "Udemy",
          color: "green-text-gradient"
        },
        
        {
          name: "Platform Designer",
          color: "pink-text-gradient"
        },
        {
          name: "Lua",
          color: "blue-text-gradient",
        },
      ],
      image: platformer,
      source_code_link: "https://github.com/Bueno803/lua-projects",
    },
    {
      name: "Database Management Application",
      description:
        "An app that organizes client information, and a schedule feature that keeps track of client's progression through a system for martial arts belt testing purposes.",
      tags: [
        {
          name: "C#",
          color: "blue-text-gradient",
        },
        {
          name: "SQL MariaDB",
          color: "green-text-gradient",
        },
      ],
      image: UPFapp,
      source_code_link: "https://github.com/Bueno803/UPF_App",
    },
    {
      name: "Web Application",
      description:
        "A group web app that allows users to create accounts customize their grocery list based on dietary preferences.",
      tags: [
        {
          name: "SCRUM",
          color: "orange-text-gradient",
        },
        {
          name: "Javascript",
          color: "blue-text-gradient",
        },
        {
          name: "React",
          color: "green-text-gradient",
        },
        {
          name: "Node.JS",
          color: "text-red-600",
        },
        {
          name: "MongoDB",
          color: "pink-text-gradient",
        },
      ],
      image: makelist,
      source_code_link: "https://github.com/Bueno803/USCWebApp",
    },
    {
      name: "Learning Management System",
      description:
        "A comprehensive software platformed designed to facilitate, the andministration, delivery and management of education and training content and that provides a centralized hub for organizations, and educational insitutions on a basic level.",
      tags: [
        {
          name: "Scrum",
          color: "orange-text-gradient",
        },
        {
          name: "Java",
          color: "blue-text-gradient",
        },
        {
          name: "JSON Database",
          color: "green-text-gradient",
        },
      ],
      image: LMS,
      source_code_link: "https://github.com/Bueno803?tab=repositories",
    },
    {
      name: "Mobile Application",
      description:
        "Developed mobile applications, including user interface design, local and cloud data storage techniques, application archetecture and utilized native device features.",
      tags: [
        {
          name: "Kotlin",
          color: "blue-text-gradient",
        },
        {
          name: "SQLite",
          color: "green-text-gradient",
        },
      ],
      image: mobileEmulator,
      source_code_link: "https://github.com/Bueno803/AndroidProjects",
    },
    {
      name: "No Epsilon Transition NFA",
      description:
        "Utilized basic theoretical principles of computing as modeled by formal languages, grammar, automata, and turing machines to create a no Epsilon transition NFA program.",
      tags: [
        {
          name: "C++",
          color: "blue-text-gradient",
        },
      ],
      image: NFA,
      source_code_link: "https://github.com/Bueno803?tab=repositories",
    },
  ];
  
  export { services, technologies, experiences, projects };