import React from 'react'
import {useState, useRef} from 'react';
import {motion} from 'framer-motion';
import emailjs from '@emailjs/browser';

import {styles} from '../styles';
import {EarthCanvas} from './canvas';
import {SectionWrapper, sectionWrapper} from '../hoc';
import {slideIn} from '../utils/motion';

// TB8B0wKlKLnanOpkz
// template_nstq0ql
// service_2h6tw0s



const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });
  const[loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const {name, value} = e.target;

    setForm({ ...form, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send('service_2h6tw0s', 'template_l3foukd', 
    {
      from_name: form.name,
      to_name: 'Abdullah',
      from_email: form.email,
      to_email: 'buenobusiness803@gmail.com',
      message: form.message,
    },
    'TB8B0wKlKLnanOpkz' ).then(() => {
      setLoading(false);
      alert('Thank you. I will get back to you as soon as possible.');

      setForm({name: '',
    email: '',
  message: '',})
    }, (error) => {
      setLoading(false)

      console.log(error);

      alert('Something went wrong.');
    })
  }

  return (
    <div class="xl:mt-12 xl:flex-row flex-col-reverse
    flex gap-10 overflow-hidden">
      <motion.div
      variants={slideIn('left', "tween", 0.2, 1)}
      className="flex-[0.75] bg-black-100 p-8  rounded-2xl">

        <p class={styles.sectionSubText}>Get in touch</p>

        <h3 class={styles.sectionHeadText}>Contact</h3>

        <form ref={formRef}
        onSubmit={handleSubmit}
        className = "mt-12 flex flex-col gap-8">

          <label class="flex flex-col">
            <span class="text-White font-medium mb-4">
            Your Name
            </span>

            <input type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
            placeholder="Enter your name"
            className="bg-tertiary py-4 px-6 placeholder:text-secondary
            text-white rounded-lg outlined0none border-none font-medium"/>

          </label>

          <label class="flex flex-col">
            <span class="text-White font-medium mb-4">
            Your Email
            </span>

            <input type="Email"
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Enter your email"
            className="bg-tertiary py-4 px-6 placeholder:text-secondary
            text-white rounded-lg outlined0none border-none font-medium"/>
            
          </label>

          <label class="flex flex-col">
            <span class="text-White font-medium mb-4">
            Your Message
            </span>

            <textarea rows="7"
            name="message"
            value={form.nmessageame}
            onChange={handleChange}
            placeholder="Enter your message"
            className="bg-tertiary py-4 px-6 placeholder:text-secondary
            text-white rounded-lg outlined0none border-none font-medium"/>
            
          </label>

          <button class="bg-tertiary py-3 px-8 outline-none w-fit text-white
          font-bold shadow-md shadow-primary rounded-xl" type="submit">
            {loading ? 'Sending...' : 'Send'}
          </button>

        </form>
      </motion.div>
      <motion.div variants={slideIn('right', 'tween', 0.2, 1)}
      className="xl:flex-1 xl:h-auto md:h-[550px] h-[350px]">
        <EarthCanvas />


      </motion.div>

    </div>
  )
}

export default SectionWrapper(Contact, "contact");