import React from 'react'
import Tilt from 'react-tilt';
import {motion} from 'framer-motion';

import {styles} from '../styles';
import {github} from '../assets';
import {SectionWrapper} from '../hoc';
import {projects} from '../constants';
import {fadeIn, textVariant} from '../utils/motion';

const ProjectCard = ({ index, name, description, tags, image, source_code_link }) => {
  return (
    <motion.div variants={fadeIn("up", "spring", index*0.5, 0.75)}>
      <Tilt 
      options={{
        max: 45, 
        scale: 1, 
        speed: 450
      }}
      className=" bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full">

        <div class="relative w-full h-[230px]">

          <img src={image} alt={name} class="w-full h-full object-cover rounded-2xl"/>
          <div class="absolute inset-0 flex justify-end m-3 card-img_hover">
            <div onClick={() => window.open
            (source_code_link, "_blank")}
            class="black-gradient w-10 h-10 rounded-full
            flex justify-center items-center cursor-pointer">
              <img src={github}
              alt="github"
              class="w-1/2 h-1/2 object-contain"/>
            </div>
          </div>
        </div>

        <div class="mt-5">
              <h3 class="text-white font-bold text[24px]">{name}</h3>

              <p class="mt-2 text-secondary text-[14px]">{description}</p>

        </div>

        <div class="mt-5 flex flex-wrap gap-2">
          {tags.map((tag) => (
            <p key={tag.name} class={`text-[14px] ${tag.color}`}>
              #{tag.name}
            </p>
          ))}
        </div>
      </Tilt>

    </motion.div>
  )

}

const Works = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p class={styles.sectionSubText}>What I have done so far</p>
        <h2 class={styles.sectionHeadText}>Projects</h2>
      </motion.div>

      <div class="w-full flex">
        <motion.p variants={fadeIn("","",0.1, 1)}
        class="mt-3 text-secondary text-[17px] max-w-3xl, leading-[30px]">
          Description of project showcasing
        </motion.p>

      </div>

      <div class="mt-20 flex flex-wrap gap-7">
        {projects.map((project, index) => (
          <ProjectCard
          key={`project-${index}`}
          index={index}
          {...project}/>
        ))}

      </div>
    </>
    
  )
}

export default SectionWrapper(Works, "work");