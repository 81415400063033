import { motion } from 'framer-motion';

import { styles } from '../styles';
import { ComputersCanvas } from './canvas';

const Hero = () => {
  return (
    <section class="relative w-full h-screen mx-auto">
      <div class={`${styles.paddingX} absolute inset-0 top-[120px] max-w-7xl mx-auto flex
      flex-row items-start gap-5`}>
        <div class="flex flex-col jusity-center items-center mt-5">
          <div class="w-5 h-5 rounded-full bg-[#915eff]" />
            <div class="w-1 sm:h-80 h-40 violet-gradient" />
        </div>

        <div>
          <h1 class={`${styles.heroHeadText} text-white`}> Hi, I'm &nbsp;
            <span class="text-[#915eff]">
               Abdullah
            </span>
          </h1>
          <p class={`${styles.heroSubText} mt-2 text-white-100`}>
          Software Developer, Personal Trainer & Martial Arts National Champion!
          </p>
        </div>
      </div>

      <ComputersCanvas />

      <div class="absolute xs:bottom-10 bottom-32 w-full flex justify-center items-center">
        <a href='#about'>
          <div class="w-9 h-16 rounded-3xl border-4 border-secondary flex justify-center items-start p-2">
            <motion.dev animate={{ y: [0, 24, 0]}} 
            transition={{duration: 1.5, repeat: Infinity, repeatType: 'loop'}}
            class="w-3 h-3 rounded-full bg-secondary mb-1" />
          </div>
        </a>
      </div>
    </section>
  )
}

export default Hero;