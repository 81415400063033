import logo from './logo.svg';
import './App.css';

import { BrowserRouter } from 'react-router-dom';
import { About, Contact, Experience, Feedbacks,
Hero, Navbar, Tech, Works, StarsCanvas, Footer } from './components';

const App = () => {
  return (
    <BrowserRouter>
      <div class="relative z-10 bg-primary">
        <div class="bg-hero-pattern bg-cover bg-no-repeat bg-center">
          <Navbar />
          <Hero />
        </div>
      <About />
      <Experience />
      <Works />
      </div>
      <div class="relative z-0">
        <Contact />
        <StarsCanvas />
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
