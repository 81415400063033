import React from 'react';
import Tilt from 'react-tilt';
import { motion } from 'framer-motion';

import { styles } from '../styles';
import { services } from '../constants';
import { SectionWrapper } from '../hoc';
import { fadeIn, textVariant } from '../utils/motion';

const ServiceCard = ({index, title, icon}) => {
  return (
    <Tilt class="xs:w-[250px] w-full">
      <motion.div
      variants={fadeIn("right", "spring", 0.5 * index, 0.75)}
      class="w-full green-print-gradiant p-[1px] rounded-[20px] shadow-card">
        {/**theese are tilt options belonging to the cards */}
        <div
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }} class="bg-tertiary rounded-[20px] py-5 px-12
        min-h-[280px] flex justify-evenly items-center flex-col">
          <img src={icon} alt={title} 
          class="w-16 h-16 object-contain"/>
          <h3 class="text-white w-24 text-xl font-bold text-center">{title}</h3>

        </div>

      </motion.div>
    </Tilt>
  )
}

const About = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p class={styles.sectionSubText}>Introduction</p>
        <h2 class={styles.sectionHeadText}>Overview</h2>
      </motion.div>
      <motion.p
      variants={fadeIn("", "", 0.1, 1)}
      class="mt-4 text-secondary text-[17px] max-2-3xl leading-[30px]">
        <ul class="list-disc">
          <li>Frontend, backend & web developer</li>
          <li>Mobile Developer</li>
          <li>National Champion, Taekwondo AAU & Junior Olympics</li>
          <li>Personal Trainer</li>
        </ul>
        {/* Senior Year Computer Science student
        with experience in frontend, backend, web,
        mobile, game & database development. Taekwondo AAU & Junior Olympics
        National Champion. Persional Trainer with years
        of experience! */}
      </motion.p>

      <div class="mt-20 flex flex-wrap gap-10">
        {/**
         * Services.map will loop over the 'services
         * and we use the arrow function to pass in each iterated
         * index of services into the servicecard as props for the 
         * servicecard function defined above
         */}
        {services.map((service,index) => 
        <ServiceCard 
        key={services.title} 
        index = {index} {...service}/>
        )}
      </div>
    </>
  )
}

export default SectionWrapper(About, "about")