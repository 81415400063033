import React from 'react'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import { motion } from 'framer-motion'
import 'react-vertical-timeline-component/style.min.css'

import { styles } from '../styles'
import { experiences } from '../constants'
import { SectionWrapper } from '../hoc'
import { textVariant } from '../utils/motion'

// If arrow function points to paranthesis you dont need a return in the function it will return automatically
// If arrow function points to brackets you need to wrap contents in return statement (eg. return (<div> contents </div>))
const ExperienceCard =({experience }) => (
  <VerticalTimelineElement
    contentStyle={{ background: '#1d1836', color: '#fff'}}
    contentArrowStyle={{ borderRight: '7px colid #232631'}}
    date={experience.date}
    iconStyle={{background: experience.iconBg}}
    icon={
      <div class="flex justify-center items-center w-full h-full">
        <img src={experience.icon}
        alt={experience.company_name}
        class="w-[90%] h-[90%] object-contain"/>
      </div>
    }>
      <div>
        <h3 class="text-white text-[24px] font-bold">
          {experience.title}
        </h3>
        <p class="text-secondary text-[16px] font-semibold"
        style={{margin: 0}}>{experience.company_name}</p>
      </div>

      <ul class="mt-5 list-disc ml-5 space-y-2">
        {experience.points.map((point, index) => (
          <li key={`experience-point-${index}`}
          class="text-white-100 text-[14px] pl-1 tracking-wider">
            {point}
          </li>
        ))}
      </ul>
  </VerticalTimelineElement>
)

const Experience = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p class={styles.sectionSubText}>Relavent Course Work</p>
        <h2 class={styles.sectionHeadText}>Courses</h2>
      </motion.div>

      <div class="mt-20 flex flex-col">
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard key={index} experience = {experience} />
           ))}
        </VerticalTimeline>
      </div>
    
    </>
  )
}

export default SectionWrapper(Experience, "experience")