import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import aceLogo from './../assets/aceLogo.png';
import {faLinkedin, faGit, faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'


const Footer = () => {
  return (
      <footer class="footer w-full bottom-0 bg-gradient-to-r from-indigo-900 to-rose-900 text-white px-0 pt-8 pb-7 text-xs leading-5">
        {/**Row */}
        <div class="w-4/5 m-auto flex flex-wrap items-start justify-between">
            {/**Col 1*/}
            <div class=" basis-1/6 p-2 ">
                <div class="App-logo ">
                    <img src={aceLogo} />
                </div>
                <p>I'm a multi-faceted individual, blending the precision of a software developer, the discipline of a personal trainer, and the wisdom of a martial arts teacher to help people achieve their goals</p>
            </div>
            {/**Col 2*/}
            <div>
              <h3 class=" font-bold w-fit mb-10 relative">Direct Contact<div class="underline"><span></span></div></h3>
              {/* <p>University of Personal Fitness</p>
              <p>Columbia, South Carolina</p>
              <p>6820 North Main Street, 29229</p> */}
              <p class="w-fit border-b my-5">buenobusiness803@gmail.com</p>
              <h4>+1-803-569-0184</h4>
            </div>
            {/**Col 3*/}
            <div>
              <h3 class="font-bold w-fit mb-10 relative">Links <div class="underline"><span></span></div></h3>
              <ul class="footerList">
                <li><a href="#about">About</a></li>
                <li><a href="#work">Projects</a></li>
                <li><a href="#contact">Contact me</a></li>
              </ul>
            </div>
            {/**Col 4*/}
            <div>
              <h3 class="font-bold w-fit mb-10 relative text-center">Socials <div class="underline"><span></span></div></h3>
                <div>
                  <ul>
                    <li class="float-left">
                      <a href="https://www.linkedin.com/in/abueno803/">
                        <FontAwesomeIcon icon={faLinkedin} class="w-6 h-6 hover:text-purple-600 leading-10 cursor-pointer"></FontAwesomeIcon>
                      </a>
                    </li>
                    <li class="float-left">
                      <a href="https://github.com/Bueno803">
                        <FontAwesomeIcon icon={faGit} class="w-6 h-6 ml-2 hover:text-gray-400"></FontAwesomeIcon>
                      </a>
                    </li>
                    <li class="float-left">
                      <a href="https://www.facebook.com/abdullah.bueno/">
                        <FontAwesomeIcon icon={faFacebook} class="w-6 h-6 ml-2 hover:text-blue-600 leading-10 cursor-pointer"></FontAwesomeIcon>
                      </a>
                    </li>
                    <li class="float-left">
                      <a href="https://www.instagram.com/abdullahbueno/">
                        <FontAwesomeIcon icon={faInstagram} class="w-6 h-6 ml-2 hover:text-purple-600 leading-10 cursor-pointer"></FontAwesomeIcon>
                      </a>
                    </li>
                    {/* <FontAwesomeIcon icon={faLinkedin} class=""></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faSpotify} class=""></FontAwesomeIcon> */}
                  </ul>
                  
                  
                  
                  
                  
                </div>
            </div>
        </div>
        <hr class=" w-11/12 border-b my-5 mx-auto" />
        <p class="text-center">Building Strong Code, Strong Bodies, and Strong Minds, One Line at a Time - All Rights Reserved</p>
      </footer>
  )
}
export default Footer
